import { ApplicationConfig, provideZoneChangeDetection, importProvidersFrom, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import nb from '@angular/common/locales/nb';

import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';


import { 
  enUS as date_en_US, 
  nb as date_nb_NO 
} from 'date-fns/locale';

import { en_US, provideNzI18n, NZ_DATE_LOCALE, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { NzModalService  } from 'ng-zorro-antd/modal';

import { nb_NO } from 'ng-zorro-antd/i18n';




registerLocaleData(nb);

import { BooktechAppService, BT_INIT_CONFIG } from '@btlib-core';

import { environment } from './../environments/environment';
import { APP_CONFIG_INIT } from './../environments/config.init';
import { APP_VERSION_CODE, APP_VERSION_NAME } from './../environments/version';

function provideBtlib(env: any): EnvironmentProviders {
  return makeEnvironmentProviders([

    {
      provide: NzModalService
    
    },
    {
      provide: BooktechAppService
    
    },
    { provide: BT_INIT_CONFIG, useFactory: () => { return { env: environment, appConfig: APP_CONFIG_INIT, 
      version: { code: APP_VERSION_CODE, name: APP_VERSION_NAME } } } },

    { provide: NZ_DATE_LOCALE, useValue: date_nb_NO }, 
    { provide: NZ_DATE_CONFIG, useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      }, 
    },
    
  ]);
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideClientHydration(), 
    // provideNzI18n(nb_NO),  //TODO: 
    provideNzI18n(en_US), 
    importProvidersFrom(FormsModule), 
    provideAnimationsAsync(), 
    provideHttpClient(),
    provideBtlib({ env: environment, appConfig: APP_CONFIG_INIT, 
      version: { code: APP_VERSION_CODE, name: APP_VERSION_NAME } }),
  ]
};
