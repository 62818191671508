import { Routes } from '@angular/router';

import { ApiPage, GotoPage, ConfirmPage } from '@btlib-core';

// module'\).then\(m => m.(.+)Module\)
// page').then(m => m.$1)
export const routesCommon: Routes = [
  
  {
    path: 'bergensportsreiser',
    children: [
    
      { path: 'order', loadComponent: () => import('./pages/bergensportsreiser/order/order.page').then(m => m.OrderPage)  },
      { path: 'list', loadComponent: () => import('./pages/bergensportsreiser/list/list.page').then(m => m.ListPage)  },
      { path: 'matches', loadComponent: () => import('./pages/bergensportsreiser/matches/matches.page').then(m => m.MatchesPage)  },
      { path: 'giftcard', loadComponent: () => import('./pages/bergensportsreiser/giftcard/giftcard.page').then(m => m.GiftcardPage)  },
      
      {
        path: 'user',
        children: [
          { path: 'index', loadComponent: () => import('./pages/bergensportsreiser/user/index/index.page').then(m => m.IndexPage)  },
      
        ]
      },
        
    ]
  },
  {
    path: 'sjofiske',
    children: [
    
      { path: 'calendar', loadComponent: () => import('./pages/sjofiske/calendar/calendar.page').then(m => m.CalendarPage)  },

    ]
  },

  {
    path: 'common',
    children: [

      {
        path: 'order',
        children: [
          // https://192.168.1.81:8205/api/bergensportsreiser/common/order/payment?id=AFL484
          { path: 'payment', loadComponent: () => import('./pages/common/order/payment/payment.page').then(m => m.PaymentPage)  }
          // { path: 'payment', loadComponent: () => import('./lib/core/pages/order/payment/payment.page').then(m => m.PaymentPage)  }
        ]
      }
    ]
  },

  {
    path: "",
    loadComponent: () => import("./pages/index/index.page").then((m) => m.IndexPage),
    
  },
  {
    path: "template-page",
    loadComponent: () => import("./pages/template-page/template-page.page").then((m) => m.TemplatePagePage),
    
  },
  {
    path: "lib",
    children: [
      { path: "api", component: ApiPage },
      { path: "goto", component: GotoPage },
      { path: "user", children: [
        { path: "confirm", component: ConfirmPage },
      ]},
    ]
  }



];


export const routes: Routes = [

  ...routesCommon,
    
  {
    path: 'api/:cid',
    children: routesCommon
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

